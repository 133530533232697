import Alpine from "@/alpinejs";

Alpine.data('cityAutocomplete', window.cityAutocomplete = () => ({
    query: '',
    /** @type {import('@p-j/geocodejson-types').GeocodeResponse} */
    response: null,
    /** @param {import('@p-j/geocodejson-types').GeocodeFeature} feature */
    displayValue(feature) {
        return feature?.properties.city;
    },
    /** @type {import('@p-j/geocodejson-types').GeocodeFeature} */
    selectedFeature: null,
    async search() {
        const q = this.query.match(/^\d\d$/) ? this.query+'000' : this.query;
        if(q.length < 3) { this.response = null; return; }
        this.response = await fetch('https://api-adresse.data.gouv.fr/search/?'+new URLSearchParams({
            type: 'municipality',
            autocomplete: '1',
            limit: '5',
            lat: this.$data.userLatitude ?? '',
            lon: this.$data.userLongitude ?? '',
            q
        }))
            .then(r => r.ok ? r.json() : Promise.reject(r))
    },
    async reverseGeocode() {
        this.response = await fetch('https://api-adresse.data.gouv.fr/reverse?'+new URLSearchParams({
            lat: this.$data.userLatitude ?? '',
            lon: this.$data.userLongitude ?? '',
        }))
            .then(r => r.ok ? r.json() : Promise.reject(r));
        this.selectedFeature = this.response?.features?.[0];
    },
    async onSubmit() {
        await this.search();
        this.selectedFeature = this.response?.features?.[0];
    },
    autocompleteInput: {
        'x-model': 'query',
        'x-combobox:input': '',
        'x-ref': 'autocompleteInput',
    }
}));
