


function storeSelector() {
    return {
        get userLongitude() {
            return this.$wire.userLongitude;
        },
        get userLatitude() {
            return this.$wire.userLatitude;
        },
        selectedCity: null,
        get $autocomplete() {
            return Alpine.$data(this.$root.querySelector('[data-city-autocomplete]'));
        },
        onOpenStoreSelectorEvent(e) {
            this.$wire.opened = true;
            this.$wire.productId = e.detail?.productId;
            this.$wire.intent = e.detail?.intent;
        },
        getUserPosition(silent = false) {
            return new Promise((resolve, reject) => {
                navigator.geolocation.getCurrentPosition(position => {
                    resolve([position.coords.latitude, position.coords.longitude]);
                }, e => {
                    if(!silent) {
                        window.alert(
                            e.code === GeolocationPositionError.PERMISSION_DENIED
                                ? 'La localisation n’est pas disponible, veuillez l’activez dans les paramètres de votre navigateur'
                                : 'La localisation n’est pas disponible sur votre appareil, veuillez essayer sur un autre navigateur'
                        );
                    }
                    reject(e);
                }, { timeout:5000 })
            });
        },
        init() {
            this.$watch('$wire.opened', (opened, previousOpened) => {
                if(previousOpened && !opened) {
                    this.$dispatch('store-selector-closed');
                }
            });
        },
    }
}

document.addEventListener('alpine:init', () => {
    Alpine.data('storeSelector', storeSelector);
});
