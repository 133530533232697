import './polyfills.js';
import './utils/sentry';
import { Livewire } from 'livewire';
import Alpine from "./alpinejs.js";
import './components/scroll-carousel.js';
import './components/header.js';
import './directives/sticky.js';
import './livewire/store-selector.js';
import '../views/components/city-autocomplete.js';
import popover from '@alpinejs/ui/src/popover';
import dialog from '@alpinejs/ui/src/dialog';
import tabs from '@alpinejs/ui/src/tabs';
import combobox from '@alpinejs/ui/src/combobox';
import disclosure from '@alpinejs/ui/src/disclosure';
// import anchor from '@alpinejs/anchor';
import { config } from "@/utils/config";
import { scrollIntoViewIfNeeded } from "@/utils";

// Alpine.plugin(anchor);
Alpine.plugin(popover);
Alpine.plugin(dialog);
Alpine.plugin(tabs);
Alpine.plugin(combobox);
Alpine.plugin(disclosure);

window.utils = { scrollIntoViewIfNeeded };

document.addEventListener('livewire:init', () => {
    let pageReloadScheduled = false;
    Livewire.hook('request', ({ fail }) => {
        fail(({ status, preventDefault }) => {
            if(!config('app.debug')) {
                preventDefault();
                if (status === 403) {
                    window.alert(`Vous n’êtes pas autorisé à effectuer cette action`);
                }
                if (status === 500) {
                    window.alert(`Une erreur est survenue, veuillez réessayer plus tard`);
                }
            }
            if (status === 419) {
                preventDefault();
                if (!pageReloadScheduled) {
                    window.alert(`Votre session n’est plus valide, appuyez sur Ok pour actualiser la page`);
                    pageReloadScheduled = true;
                    setTimeout(() => {
                        location.reload();
                    });
                }
            }
        })
    })
})

Livewire.start();
