import Alpine from "../alpinejs";

Alpine.data('scrollCarousel', window.scrollCarousel = ({ slidesPerPage, interval, loop }) => ({
    currentSlide: 0,
    slidesPerPage: 0,
    slidesCount: 0,
    atBeginning: false,
    atEnd: false,
    loop,
    get indicatorsCount() {
        return this.slidesPerPage
            ? Math.ceil(this.slidesCount / this.slidesPerPage)
            : 0;
    },
    get isInactive() {
        return this.slidesPerPage >= this.slidesCount;
    },
    handleSlideTo(e) {
        this.slideTo(e.detail.index);
    },
    handleScroll(e) {
        const slide = this.$el.querySelector('[x-ref="slide"]');
        this.currentSlide = this.nextIndex(Math.round(e.target.scrollLeft / slide.offsetWidth));
        if(e.target.scrollLeft + e.target.offsetWidth >= e.target.scrollWidth) {
            this.currentSlide = this.nextIndex(this.slidesCount);
        }
        this.updatePositions();
    },
    nextIndex(index) {
        return Math.min(Math.max(index, 0), this.slidesCount - this.slidesPerPage);
    },
    slidePrev() {
        this.slideTo(this.currentSlide - this.slidesPerPage);
    },
    slideTo(index, { behavior = 'smooth' } = {}) {
        const slides = [...this.$root.querySelectorAll('[x-ref="slide"]')];
        const scroller = slides[0].parentElement;
        this.$refs.scroller.scrollTo({
            left: slides[this.nextIndex(index)].offsetLeft - parseInt(getComputedStyle(scroller).paddingLeft),
            behavior
        });
    },
    slideNext() {
        if(document.hidden) {
            this.scheduleNext();
            return;
        }
        if(this.currentSlide >= this.slidesCount - this.slidesPerPage) {
            if(loop){
                this.slideTo(0);
            }
        } else {
            this.slideTo(this.currentSlide + this.slidesPerPage);
        }
        this.scheduleNext();
    },
    scheduleNext() {
        if(interval) {
            clearTimeout(this.timeout);
            this.timeout = setTimeout(() => this.slideNext(), interval);
        }
    },
    layout() {
        const slide = this.$el.querySelector('[x-ref="slide"]');
        const parentStyle = window.getComputedStyle(slide.parentElement);
        const gapX = parseFloat(parentStyle.columnGap) || 0;
        const parentWidth = slide.parentElement.offsetWidth - parseFloat(parentStyle.paddingRight)  - parseFloat(parentStyle.paddingLeft);
        const perPage = (parentWidth + gapX) / (slide.offsetWidth + gapX);
        // round when items have fraction width
        if(Math.ceil(perPage) - perPage <= 0.1) {
            this.slidesPerPage = slidesPerPage ?? Math.ceil(perPage);
        } else {
            this.slidesPerPage = slidesPerPage ?? Math.floor(perPage);
        }
        this.updatePositions();
    },
    updatePositions() {
        this.atBeginning = this.$refs.scroller.scrollLeft === 0;
        this.atEnd = this.$refs.scroller.scrollLeft + this.$refs.scroller.offsetWidth >= this.$refs.scroller.scrollWidth;
    },
    init() {
        this.slidesCount = [...this.$el.querySelectorAll('[x-ref="slide"]')].length;
        if(this.slidesCount) {
            this.layout();
            new ResizeObserver(() => this.layout()).observe(this.$el);
        }
        if(interval) {
            new IntersectionObserver((entries, observer) => {
                if(entries[0].isIntersecting) {
                    this.scheduleNext();
                    observer.disconnect();
                }
            }, { threshold: .75 }).observe(this.$el);
        }
    },
}));
